import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@components/layout";
import Seo from "@components/seo";
import TopNav from "@components/topNav";
import Footer from "@components/footer";
import { CheckIcon } from "@heroicons/react/outline";

import { IoPlay, IoPause } from "react-icons/io5";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const features = [
  {
    name: "Data light via web or WhatsApp",
    description:
      "Boost is optimised for low bandwith and requires minimal data",
  },
  {
    name: "Free to download for offline use",
    description:
      "Ornare donec rhoncus vitae nisl velit, neque, mauris dictum duis. Nibh urna non parturient.",
  },
  {
    name: "Available to individuals and organisations",
    description:
      "Etiam cras augue ornare pretium sit malesuada morbi orci, venenatis. Dictum lacus.",
  },
  {
    name: "Regularly updated with the latest information.",
    description:
      "Interdum quam pulvinar turpis tortor, egestas quis diam amet, natoque. Mauris sagittis.",
  },
];

const NewsPage = () => {
  const results = [
    {
      title: "BoostBot launches on WhatsApp",
      href: "#",
      category: { name: "Article", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      imageUrl: "hero-2.jpeg",
      readingTime: "6 min",
      author: {
        name: "Roel Aufderehar",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Be In the Know new services",
      href: "#",
      category: { name: "Video", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
      date: "Mar 10, 2020",
      datetime: "2020-03-10",
      imageUrl: "hero-11.jpeg",
      readingTime: "4 min",
      author: {
        name: "Brenna Goyette",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Life skills app Mozambique",
      href: "#",
      category: { name: "Case Study", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Feb 12, 2020",
      datetime: "2020-02-12",
      imageUrl: "hero-10.jpeg",
      readingTime: "11 min",
      author: {
        name: "Daniela Metz",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "BoostBot launches on WhatsApp",
      href: "#",
      category: { name: "Article", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      imageUrl: "hero-2.jpeg",
      readingTime: "6 min",
      author: {
        name: "Roel Aufderehar",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Be In the Know new services",
      href: "#",
      category: { name: "Video", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
      date: "Mar 10, 2020",
      datetime: "2020-03-10",
      imageUrl: "hero-11.jpeg",
      readingTime: "4 min",
      author: {
        name: "Brenna Goyette",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Life skills app Mozambique",
      href: "#",
      category: { name: "Case Study", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Feb 12, 2020",
      datetime: "2020-02-12",
      imageUrl: "hero-10.jpeg",
      readingTime: "11 min",
      author: {
        name: "Daniela Metz",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "BoostBot launches on WhatsApp",
      href: "#",
      category: { name: "Article", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
      date: "Mar 16, 2020",
      datetime: "2020-03-16",
      imageUrl: "hero-2.jpeg",
      readingTime: "6 min",
      author: {
        name: "Roel Aufderehar",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Be In the Know new services",
      href: "#",
      category: { name: "Video", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
      date: "Mar 10, 2020",
      datetime: "2020-03-10",
      imageUrl: "hero-11.jpeg",
      readingTime: "4 min",
      author: {
        name: "Brenna Goyette",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
    {
      title: "Life skills app Mozambique",
      href: "#",
      category: { name: "Case Study", href: "#" },
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
      date: "Feb 12, 2020",
      datetime: "2020-02-12",
      imageUrl: "hero-10.jpeg",
      readingTime: "11 min",
      author: {
        name: "Daniela Metz",
        href: "#",
        imageUrl:
          "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    },
  ];

  return (
    <div>
      <div className="relative bg-boostPink overflow-hidden">
        <div className="max-w-6xl mx-auto">
          <div className="relative z-10 pb-8 bg-boostPink sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidde pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-boostPink transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 180 585"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path"
                  fill="#BD7AB2"
                  fillRule="nonzero"
                  points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                ></polygon>
              </g>
            </svg>

            <TopNav theme={"sunset"} />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left pb-16"> {/* Padding botom if no video button */}
                <h1 className="text-4xl tracking-tight font-extrabold text-midBlue sm:text-5xl md:text-6xl font-heading">
                  Boost 
                  {/* <span className="block text-indigo-600 xl:inline"></span> */}
                </h1>
                <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Giving community health workers the information and resources
                  they need on HIV, sexual health and COVID-19.
                </p>

                <div className="mt-4 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  {/* <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-midBlue bg-yellow hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Our projects
                  </a>
                </div> */}
                  {/* <div className="">
                    <button
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 text-base text-midBlue font-medium rounded-md  bg-white hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                    >
                      Watch video
                      <IoPlay
                        className="inline-block w-7 h-7 pl-1"
                        aria-hidden="true"
                      />
                    </button>
                  </div> */}
                </div>

                {/* <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Avert is using the power of digital communications to increase
                knowledge and understanding of HIV and sexual health, so people
                can make informed choices and live healthily.
              </p> */}
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/hero-boost.jpeg"
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="Avert"
            placeholder="blurred"
            layout="fullWidth"
            // width={1748}
            // height={1327}
          />
        </div>
      </div>
      <div className="">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                      Our Story
                    </span> */}
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">
                What is Boost?
                </span>
              </h1>
              <p className="mt-8 text-xl text-fossil leading-8">
              Boost is a digital, mobile-first brand that aims to increase the
      knowledge, skills and confidence of community health workers in
      Southern Africa.
              </p>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-fossil mx-auto">


              <figure>

                      <img
        className="w-full rounded-lg"
        src="boost-1.jpg"
        alt="Boost screens"

      />
                {/* <figcaption>
                  Put a nice team photo set in Platform9 here
                </figcaption> */}
              </figure>

            </div>
          </div>
        </div>
      </div>




      <div className="relative bg-boostPink pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8  border-t-8 border-b-8 border-white">
        <div className="relative max-w-7xl mx-auto">
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="flex v-screen">
              <div className="m-auto">
                {/* <svg
                  className="h-12 w-12 text-sunset flex v-screen"
                  fill="currentColor"
                  // viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg> */}
                <div className="flex v-screen">
                  <div className="m-auto">
                    <svg
                      className="h-12 w-12 text-midBlue flex v-screen p-2"
                      viewBox="0 0 227 212"
                      version="1.1"
                    >
                      <path
                        d="M97.714,10.08 L97.714,47.947 C97.714,53.084 93.55,57.251 88.41,57.251 C70.084,57.251 60.121,76.041 58.741,113.131 L88.41,113.131 C93.55,113.131 97.714,117.293 97.714,122.436 L97.714,202.39 C97.714,207.527 93.55,211.688 88.41,211.688 L9.304,211.688 C4.164,211.688 0,207.527 0,202.39 L0,122.436 C0,104.656 1.791,88.331 5.32,73.92 C8.941,59.154 14.496,46.239 21.826,35.535 C29.374,24.541 38.817,15.908 49.893,9.897 C61.041,3.851 74,0.782 88.416,0.782 C93.55,0.782 97.714,4.947 97.714,10.08 Z"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M217.646,0.782 C203.235,0.782 190.27,3.85 179.123,9.897 C168.046,15.908 158.603,24.541 151.056,35.535 C143.721,46.245 138.17,59.154 134.544,73.931 C131.015,88.341 129.23,104.655 129.23,122.435 L129.23,202.389 C129.23,207.526 133.395,211.687 138.528,211.687 L217.641,211.687 C222.774,211.687 226.938006,207.526 226.938006,202.389 L226.938006,122.435 C226.938006,117.292 222.774,113.13 217.641,113.13 L188.389,113.13 C189.751,76.04 199.575,57.25 217.641,57.25 C222.774,57.25 226.938006,53.083 226.938006,47.946 L226.938006,10.08 C226.944,4.947 222.78,0.782 217.646,0.782 Z"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                    </svg>
                  </div>
                </div>
                <blockquote>
                  <p className="mb-3 text-2xl font-medium text-white mt-3 text-center  max-w-prose">
                    The app has made it easy for peer educators to share correct
                    information with their clients.
                  </p>
                </blockquote>
                <div className="flex v-screen">
                  <div className="m-auto">
                    <svg
                      className="h-12 w-12 text-midBlue flex v-screen p-2"
                      viewBox="0 0 227 212"
                      version="1.1"
                    >
                      <path
                        d="M217.904,0.515 L138.795,0.515 C133.659,0.515 129.491,4.679 129.491,9.819 L129.491,89.768 C129.491,94.907 133.652,99.072 138.795,99.072 L168.461,99.072 C167.084,136.162 157.118,154.949 138.795,154.949 C133.659,154.949 129.491,159.113 129.491,164.253 L129.491,202.12 C129.491,207.257 133.652,211.424 138.795,211.424 C153.206,211.424 166.168,208.35 177.316,202.31 C188.387,196.292 197.833,187.671 205.375,176.665 C212.704,165.964 218.261,153.055 221.884,138.281 C225.412,123.87 227.203004,107.551 227.203004,89.768 L227.203004,9.818 C227.208,4.679 223.035,0.515 217.904,0.515 Z"
                        id="Path"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M88.672,0.515 L9.563,0.515 C4.432,0.515 0.265,4.679 0.265,9.819 L0.265,89.768 C0.265,94.907 4.432,99.072 9.563,99.072 L38.815,99.072 C37.45,136.162 27.627,154.949 9.563,154.949 C4.432,154.949 0.265,159.113 0.265,164.253 L0.265,202.12 C0.265,207.257 4.432,211.424 9.563,211.424 C23.973,211.424 36.941,208.35 48.089,202.31 C59.165,196.292 68.605,187.671 76.147,176.665 C83.489,165.964 89.044,153.055 92.668,138.275 C96.191,123.87 97.977,107.551 97.977,89.775 L97.977,9.818 C97.976,4.679 93.809,0.515 88.672,0.515 Z"
                        id="Path"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                    </svg>
                    {/* <svg
                      className="h-12 w-12 text-sunset flex v-screen"
                      fill="currentColor"
                      // viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg> */}
                  </div>
                </div>

                <footer className="mt-6">
                  <p className="text-base font-medium text-white text-center">
                    Africaid
                  </p>
                  {/* <p className="text-base font-medium text-black text-center">
                    Executive Director, UNAIDS
                  </p> */}
                </footer>
              </div>
            </div>
          </div>
          {/* <div className="mt-12 mb-12  max-w-prose  mx-auto ">
       

            <blockquote>
              <p>
                We believe in individuals’ inalienable right to health, and
                their right to make informed choices around their sexual health.
                We aim for everything we do, and how we do it, to be underpinned
                by our set of core values.
              </p>
            </blockquote>
            <p>- Winnie Byanyima, Executive Director, UNAIDS</p>
          </div> */}
        </div>
      </div>

      <div className="mt-12 mb-2 prose prose-indigo prose-lg text-fossil mx-auto">
        <span className="mt-2 block text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl font-heading">
          Purpose built for community health workers
        </span>
        <p>
          Boost was designed and developed in collaboration with more than 100
          community health workers from Malawi, Zambia and Zimbabwe. Boost
          provides easy, up-to-date, visual and interactive materials on HIV,
          sexual health and COVID-19 to support community health workers’
          ongoing learning and equip them with fun, interactive tools that they
          can use and share with their clients and communities.
        </p>

        <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8 mb-12">
          {/* <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Boost is:</h2>
          </div> */}
          {/* <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8"> */}
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-7 w-7 text-boostPink"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                {/* <dd className="mt-2 ml-9 text-base text-fossil">
                  {feature.description}
                </dd> */}
              </div>
            ))}
          {/* </dl> */}
        </div>
      </div>

      </div>


      <div className="bg-lightBlue">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="pb-0 pt-12 text-3xl text-leading font-bold text-white">
            Features
          </h2>
        </div>
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none bg-lightBlue">
            {/* 1 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/card-based.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Card-based user interface
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    Content is delivered as collections of cards, including
                    slideshows, quizzes, myth busters and other learning tools.
                  </p>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/progress-tracking.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Progress tracking
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    Track your progress and units completed via the dashboard
                    progress rings.
                  </p>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/bookmarks.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Bookmarks
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    Save cards or collections of cards to your favourites, and
                    retrieve them later for revision.
                  </p>
                </div>
              </div>
            </div>

            {/* 4 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/share.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  width={1600}
                  height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    Share with others
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    Share content cards or collections of cards via WhatsApp.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 mb-8 prose prose-indigo prose-lg text-fossil mx-auto">
        <span className="mt-2 block text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl font-heading">
          BoostBot – Chatbot service
        </span>
        <p>
          The WhatsApp chatbot service uses Machine Learning to answer
          questions, and returns optimised Boost content from the same system
          that powers the web app.
        </p>
        <p>
          A large number of Boost’s target users have access to WhatsApp as it
          is the number one messaging app in southern Africa.
        </p>
        <p className="p-0">
          <span className="pb-0 font-bold">Machine Learning</span> - The Bot
          learns over time by answering common questions about HIV and other
          related health questions and provides advice
        </p>
        <p className="p-0">
          <span className="pb-0 font-bold">BoostBot API</span> - The Boost API
          provides a simple way for BoostBot to retrieve content and distribute
          it to different apps
        </p>
      </div>

      <div className="relative bg-boostPink pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8  border-t-8 border-b-8 border-white">
        <div className="relative max-w-7xl mx-auto">
          <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
            <div className="flex v-screen">
              <div className="m-auto">
                <div className="flex v-screen">
                  <div className="m-auto">
                    <svg
                      className="h-12 w-12 text-midBlue flex v-screen p-2"
                      viewBox="0 0 227 212"
                      version="1.1"
                    >
                      <path
                        d="M97.714,10.08 L97.714,47.947 C97.714,53.084 93.55,57.251 88.41,57.251 C70.084,57.251 60.121,76.041 58.741,113.131 L88.41,113.131 C93.55,113.131 97.714,117.293 97.714,122.436 L97.714,202.39 C97.714,207.527 93.55,211.688 88.41,211.688 L9.304,211.688 C4.164,211.688 0,207.527 0,202.39 L0,122.436 C0,104.656 1.791,88.331 5.32,73.92 C8.941,59.154 14.496,46.239 21.826,35.535 C29.374,24.541 38.817,15.908 49.893,9.897 C61.041,3.851 74,0.782 88.416,0.782 C93.55,0.782 97.714,4.947 97.714,10.08 Z"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M217.646,0.782 C203.235,0.782 190.27,3.85 179.123,9.897 C168.046,15.908 158.603,24.541 151.056,35.535 C143.721,46.245 138.17,59.154 134.544,73.931 C131.015,88.341 129.23,104.655 129.23,122.435 L129.23,202.389 C129.23,207.526 133.395,211.687 138.528,211.687 L217.641,211.687 C222.774,211.687 226.938006,207.526 226.938006,202.389 L226.938006,122.435 C226.938006,117.292 222.774,113.13 217.641,113.13 L188.389,113.13 C189.751,76.04 199.575,57.25 217.641,57.25 C222.774,57.25 226.938006,53.083 226.938006,47.946 L226.938006,10.08 C226.944,4.947 222.78,0.782 217.646,0.782 Z"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                    </svg>
                  </div>
                </div>
                <blockquote>
                  <p className="mb-3 text-2xl font-medium text-white mt-3 text-center  max-w-prose">
                    What I love most about Boost is that it is simple but very
                    educating and it will help me so much in my field.
                  </p>
                </blockquote>
                <div className="flex v-screen">
                  <div className="m-auto">
                    <svg
                      className="h-12 w-12 text-midBlue flex v-screen p-2"
                      viewBox="0 0 227 212"
                      version="1.1"
                    >
                      <path
                        d="M217.904,0.515 L138.795,0.515 C133.659,0.515 129.491,4.679 129.491,9.819 L129.491,89.768 C129.491,94.907 133.652,99.072 138.795,99.072 L168.461,99.072 C167.084,136.162 157.118,154.949 138.795,154.949 C133.659,154.949 129.491,159.113 129.491,164.253 L129.491,202.12 C129.491,207.257 133.652,211.424 138.795,211.424 C153.206,211.424 166.168,208.35 177.316,202.31 C188.387,196.292 197.833,187.671 205.375,176.665 C212.704,165.964 218.261,153.055 221.884,138.281 C225.412,123.87 227.203004,107.551 227.203004,89.768 L227.203004,9.818 C227.208,4.679 223.035,0.515 217.904,0.515 Z"
                        id="Path"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M88.672,0.515 L9.563,0.515 C4.432,0.515 0.265,4.679 0.265,9.819 L0.265,89.768 C0.265,94.907 4.432,99.072 9.563,99.072 L38.815,99.072 C37.45,136.162 27.627,154.949 9.563,154.949 C4.432,154.949 0.265,159.113 0.265,164.253 L0.265,202.12 C0.265,207.257 4.432,211.424 9.563,211.424 C23.973,211.424 36.941,208.35 48.089,202.31 C59.165,196.292 68.605,187.671 76.147,176.665 C83.489,165.964 89.044,153.055 92.668,138.275 C96.191,123.87 97.977,107.551 97.977,89.775 L97.977,9.818 C97.976,4.679 93.809,0.515 88.672,0.515 Z"
                        id="Path"
                        fill="#17607D"
                        fillRule="nonzero"
                      ></path>
                    </svg>
                  </div>
                </div>

                <footer className="mt-6">
                  <p className="text-base font-medium text-white text-center">
                    Loveness, Community health worker in Zambia
                  </p>
                  {/* <p className="text-base font-medium text-black text-center">
                    Executive Director, UNAIDS
                  </p> */}
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 mb-8 prose prose-indigo prose-lg text-fossil mx-auto">
        <span className="mt-2 block text-3xl font-bold tracking-tight text-gray-900 sm:text-3x font-heading">
          Why was Boost developed?
        </span>
        <p>
          Community health workers support millions of people with, or at risk
          of HIV in Southern Africa. They are often people’s first point of
          contact with the health system. To play an effective role, they need
          clear, accessible, and accurate information so they can provide high
          quality care to their communities.
        </p>
        <p>
          Often community health workers rely on paper-based resources which are
          difficult to keep up-do-date, or in-person training which vary in
          terms of regularity and quality. There is also a lack of digital
          information resources specifically developed and tailored to the needs
          of community health workers and the Southern African context.
        </p>
        <p>
          With access to mobile phones growing in Southern Africa, we saw an
          opportunity to address this need by providing a digital alternative: a
          more convenient way for community health workers to stay up-do-date
          and have the information they need to hand throughout the day.
        </p>
      </div>

      <div className="bg-lightBlue">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="pb-0 pt-12 text-3xl text-leading font-bold text-white font-heading">
            Features
          </h2>
        </div>
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-5 lg:max-w-none bg-lightBlue">
            {/* 1 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/google.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-l font-semibold text-gray-900 font-heading">
                    Google Play
                  </p>
                  <p className="mt-3 text-sm text-fossil">
                    Download the full app to use offline from the Google Play
                    Store
                  </p>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/kai-os.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-l font-semibold text-gray-900 font-heading">KaiOS</p>
                  <p className="mt-3 text-sm text-fossil">
                    Go to the KaiOS store to download Boost for feature phones
                  </p>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/boost.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  // width={1600}
                  // height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-l font-semibold text-gray-900 font-heading">Web app</p>
                  <p className="mt-3 text-sm text-fossil">
                    Access all the Boost content online at boost. avert.org
                  </p>
                </div>
              </div>
            </div>

            {/* 4 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/whatsapp.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  width={1600}
                  height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-l font-semibold text-gray-900 font-heading">WhatsApp</p>
                  <p className="mt-3 text-sm text-fossil">
                    Send questions to the WhatsApp bot to access Boost
                    information via chat
                  </p>
                </div>
              </div>
            </div>

            {/* 5 */}
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img
                  src="/features/facebook.svg"
                  className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                  alt="Avert"
                  placeholder="blurred"
                  //layout="fullWidth"
                  width={1600}
                  height={874}
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-l font-semibold text-gray-900 font-heading">Facebook</p>
                  <p className="mt-3 text-sm text-fossil">
                    Join the Boost Facebook group to discuss content further and
                    talk to other CHWs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-boostPink  pt-12 sm:pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Trusted by comunity health workers worldwide
            </h2>
            <p className="mt-3 text-xl text-white sm:mt-4">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Repellendus repellat laudantium.
            </p>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-boostPink sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-boostPink bg-opacity-30" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className="bg-boostPink sm:grid sm:grid-cols-3">
                  <div className="flex flex-col p-6 text-center ">
                    <dt className="order-2 mt-2 text-xl font-bold leading-6 text-midBlue uppercase font-heading">
                      Boost users
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-white font-heading">
                      10,000
                    </dd>
                  </div>
                  <div className="flex flex-col p-6 text-center">
                    <dt className="order-2 mt-2 text-xl font-bold leading-6 text-midBlue uppercase font-heading">
                      Questions asked
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-white font-heading">
                      2M
                    </dd>
                  </div>
                  <div className="flex flex-col p-6 text-center ">
                    <dt className="order-2 mt-2 text-xl font-bold leading-6 text-midBlue uppercase font-heading">
                      Downloads a month
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-white font-heading">
                      100,000
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Partners */}

      <div>
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <p className="text-center font-semibold  text-gray-800 tracking-wide text-xl pb-4 font-heading">
            Working in partnership with ...
          </p>

          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            slidesPerGroup={2}
            // centeredSlides={true}
            // autoplay={{
            //   delay: 4000,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            // pagination={{
            //   clickable: true
            // }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="pt-6 pl-16">
              <img src="partners/vso.png" alt="VSO" />
            </SwiperSlide>
            <SwiperSlide className="pt-4 pl-12">
              <img src="partners/africaid.png" alt="Africaid" />
            </SwiperSlide>
            <SwiperSlide className="pt-4 pl-12">
              <img src="partners/ophid.png" alt="KaiOS" />
            </SwiperSlide>
            <SwiperSlide className="pt-6 pl-12">
              <img src="partners/mpt.png" alt="MPT" />
            </SwiperSlide>

            <SwiperSlide className="pt-6 pl-16">
              <img src="partners/vso.png" alt="VSO" />
            </SwiperSlide>
            <SwiperSlide className="pt-4 pl-12">
              <img src="partners/africaid.png" alt="Africaid" />
            </SwiperSlide>
            <SwiperSlide className="pt-4 pl-12">
              <img src="partners/ophid.png" alt="KaiOS" />
            </SwiperSlide>
            <SwiperSlide className="pt-6 pl-12">
              <img src="partners/mpt.png" alt="MPT" />
            </SwiperSlide>

            <SwiperSlide className="pt-6 pl-16">
              <img src="partners/vso.png" alt="VSO" />
            </SwiperSlide>
            <SwiperSlide className="pt-4 pl-12">
              <img src="partners/africaid.png" alt="Africaid" />
            </SwiperSlide>
            <SwiperSlide className="pt-4 pl-12">
              <img src="partners/ophid.png" alt="KaiOS" />
            </SwiperSlide>
            <SwiperSlide className="pt-6 pl-12">
              <img src="partners/mpt.png" alt="MPT" />
            </SwiperSlide>

            {/* <SwiperSlide className="pt-6 pl-12">
              <img src="partners/kai-os.png" alt="KaiOS" />
            </SwiperSlide> */}
          </Swiper>

          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-boostPink hover:bg-boostPink"
              >
                Our partners
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NewsPage;
